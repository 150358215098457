import { createAsyncThunk } from "@reduxjs/toolkit";
import { FilterParamsDto } from "../../../shared/services/dto/filter-params.dto";
import { ParamsService } from "../../../shared/services/params.service";

export const filterParamsAction = createAsyncThunk(
    'laptop/filter',
    async( dto: FilterParamsDto, thunkApi:any) => {
        try {
            const data = await ParamsService.fetchParams(dto);
            console.log(data);
            return data;
        } catch(error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

