import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { NotebookFormParams, Option } from "../model/interface";
import { SelectField } from "./SelectField";
import { DriveField } from "./DriveField";
import { changeField } from "../model/reducer";
import { arrayToOptions } from "../../../shared/utils";
import { useEffect } from "react";
import { filterParamsAction } from "../actions/filter-params.action";
import { FilterParamsDto } from "../../../shared/services/dto/filter-params.dto";
import { searchLaptopAction } from "../actions/search-laptop.action";
import { LaptopSearchDto } from "../../../shared/services/dto/laptop-search.dto";

export const DetailSearch = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useSelector((state: RootState) => state.notebookForm.fields);
    const laptop = useSelector((state: RootState) => state.notebookForm.laptop);
    const params:NotebookFormParams = useSelector((state: RootState) => state.notebookForm.params) as NotebookFormParams;

    const {
        brand,
        os,
        processor,
        ram,
        diagonal,
        graphicsCard,
        memory_1_type,
        memory_2_type,
        memory_2_volume
    } = fields;

    useEffect(() => {
        if (laptop.laptop.id === 0 && ram && processor && os && graphicsCard && diagonal) {
            const dto:LaptopSearchDto = {
                cpu: processor.value,
                ram: parseInt(ram.value),
                os: os.value,
                graphics_card: graphicsCard.value,
                display: diagonal.value,

            }
            dispatch(searchLaptopAction(dto));
        }

        if (brand && laptop.laptop.id === 0) {
            console.log("[DEBUG] use effect...");
            const dto:FilterParamsDto = {
                brand: brand!.value,
            }

            if (ram) {
                dto.ram = parseInt(ram.value);
            }

            if (processor) {
                dto.cpu = processor.value;
            }

            if (os) {
                dto.os = os.value;
            }

            if (graphicsCard) {
                dto.graphics_card = graphicsCard.value;
            }

            if (diagonal) {
                dto.display = diagonal.value;
            }
        
            dispatch(filterParamsAction(dto));
        }
    }, [ram, processor, os, graphicsCard, diagonal]);

    const handleAddDiskClick = () => {
        dispatch(changeField({ label: "memory_2_type", value: { value: "hdd" } as Option }));
        dispatch(changeField({ label: "memory_2_volume", value: { value: "" } as Option }));
    }
    console.log("[DEBUG] Params in component: ", params);
    return (<>
        <h3 className="mt-50">Характеристики устройства</h3>
        <SelectField label="Видеокарта" name="graphicsCard" options={arrayToOptions(params.graphics_card)} />

        <SelectField label="Объем оперативной памяти" name="ram" options={arrayToOptions(params.ram)}  />
        <SelectField label="Процессор" name="processor" options={arrayToOptions(params.cpu)} />
        <SelectField
          label="Разрешение и диагональ экрана"
          name="diagonal"
          options={arrayToOptions(params.display)}
        />
        <SelectField label="Операционная система" name="os" options={arrayToOptions(params.os)} />

        {/* <h3 className="mt-50">Жесткие диски</h3>
        <DriveField type={memory_1_type} volumeKey="memory_1_volume" typeKey="memory_1_type" index={1} />
        {memory_2_volume && <DriveField type={memory_2_type} volumeKey="memory_2_volume" typeKey="memory_1_type" index={2} /> }
        {!memory_2_volume && <button className="request__btn" onClick={handleAddDiskClick}>Добавить диск</button>  } */}


    
    </>);
}   