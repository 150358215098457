import { IProps } from "../../interfaces/IProps";

export const Tooltip = ({ children }: IProps) => (
  <span className="tooltip tooltip--inline">
    <span className="tooltip__icon">
      <svg
        className="tooltip__svg"
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx={9} cy={9} r="8.5" stroke="white" />
        <path
          d="M8.3098 11.4054V11.1081C8.3098 10.7928 8.34169 10.5135 8.40547 10.2703C8.47836 10.027 8.59681 9.80631 8.76082 9.60811C8.92483 9.4009 9.0615 9.24775 9.17084 9.14865C9.28018 9.04054 9.44875 8.89189 9.67654 8.7027C9.86788 8.54955 10.0182 8.42793 10.1276 8.33784C10.2369 8.23874 10.3554 8.11261 10.4829 7.95946C10.6196 7.7973 10.7153 7.62162 10.7699 7.43243C10.8337 7.24324 10.8656 7.03153 10.8656 6.7973C10.8656 6.45495 10.7836 6.15315 10.6196 5.89189C10.4647 5.63063 10.246 5.43243 9.96355 5.2973C9.68109 5.15315 9.36219 5.08108 9.00683 5.08108C8.48747 5.08108 8.04556 5.22973 7.68109 5.52703C7.32574 5.81532 7.1344 6.20721 7.10706 6.7027H6C6.03645 5.86486 6.34169 5.20721 6.91572 4.72973C7.49886 4.24324 8.1959 4 9.00683 4C9.42597 4 9.81321 4.06757 10.1686 4.2027C10.533 4.32883 10.8519 4.50901 11.1253 4.74324C11.3986 4.97748 11.6128 5.27477 11.7677 5.63514C11.9226 5.98649 12 6.37387 12 6.7973C12 7.06757 11.9681 7.31982 11.9043 7.55405C11.8497 7.77928 11.7813 7.97297 11.6993 8.13514C11.6264 8.28829 11.5171 8.44595 11.3713 8.60811C11.2255 8.77027 11.1025 8.8964 11.0023 8.98649C10.9021 9.06757 10.7654 9.18018 10.5923 9.32432C10.4282 9.45946 10.3098 9.55856 10.2369 9.62162C10.1731 9.67568 10.0774 9.77027 9.94989 9.90541C9.83143 10.0315 9.74487 10.1486 9.69021 10.2568C9.63554 10.3559 9.58542 10.482 9.53986 10.6351C9.49431 10.7793 9.47153 10.9369 9.47153 11.1081V11.4054H8.3098ZM8.22779 14V12.5676H9.55353V14H8.22779Z"
          fill="white"
        />
      </svg>
    </span>
    <span className="tooltip__text tooltip__text--info-right">{children ?? "Здесь скоро появится контент"}</span>
  </span>
);
