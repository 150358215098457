import { createAsyncThunk } from "@reduxjs/toolkit";
import { LaptopSearchDto } from "../../../shared/services/dto/laptop-search.dto";
import { LaptopService } from "../../../shared/services/laptop.service";

export const searchLaptopAction = createAsyncThunk(
    'laptop/search',
    async(dto: LaptopSearchDto, thunkApi:any) => {
        try {
            const data = await LaptopService.search(dto);
            console.log(data);
            return data;
        } catch(error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);