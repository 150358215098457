import { CPU_SERIVCE_BASE_URL, DISPLAY_SERVICE_BASE_URL, GRAPHICS_CARD_BASE_URL, HDD_SERVICE_BASE_URL, MANUFACTURER_SERIVCE_BASE_URL, OS_SERVICE_BASE_URL, RAM_SERVICE_BASE_URL, SSD_SERVICE_BASE_URL } from "../constants";
import { cpuMappingThunk } from "./mapping/cpu.mapping";
import { Service } from "./service";
import { ServiceFactory } from "./service.factory";
import { api } from "../api/api";
import { displayMappingThunk } from "./mapping/display.mapping";
import { osMappingThunk } from "./mapping/os.mapping";
import { ramMappingThunk } from "./mapping/ram.mapping";
import { memoryMappingThunk } from "./mapping/memory.mapping";
import { gcMappingThunk } from "./mapping/graphics-card.mapping";

export enum ServiceEnum {
    CPU_SERVICE = "CpuService",
    MANUFACTURER_SERIVCE = "ManufacturerService",
    DISPLAY_SERVICE = "DisplayService",
    OS_SERVICE = "OsService",
    RAM_SERVICE = "RamService",
    HDD_SERVICE = "HddService",
    SSD_SERVICE = "SsdService",
    GRAPHICS_CARD_SERVICE = "GraphicsCardService",
}

const ServicesMap = [
    {
        name: ServiceEnum.CPU_SERVICE,
        uri: CPU_SERIVCE_BASE_URL,
        mappingThunk: cpuMappingThunk,
    },
    {
        name: ServiceEnum.MANUFACTURER_SERIVCE,
        uri: MANUFACTURER_SERIVCE_BASE_URL,
        preload: true,
    },
    {
        name: ServiceEnum.DISPLAY_SERVICE,
        uri: DISPLAY_SERVICE_BASE_URL,
        mappingThunk: displayMappingThunk,
    },
    {
        name: ServiceEnum.OS_SERVICE,
        uri: OS_SERVICE_BASE_URL,
        mappingThunk: osMappingThunk,
    },
    {
        name: ServiceEnum.RAM_SERVICE,
        uri: RAM_SERVICE_BASE_URL,
        mappingThunk: ramMappingThunk,
    },
    {
        name: ServiceEnum.HDD_SERVICE,
        uri: HDD_SERVICE_BASE_URL,
        mappingThunk: memoryMappingThunk,
    },
    {
        name: ServiceEnum.SSD_SERVICE,
        uri: SSD_SERVICE_BASE_URL,
        mappingThunk: memoryMappingThunk,
    },
    {
        name: ServiceEnum.GRAPHICS_CARD_SERVICE,
        uri: GRAPHICS_CARD_BASE_URL,
        mappingThunk: gcMappingThunk,
    },
];

export interface ServiceMap {
    name: string;
    uri: string;
    mappingThunk?: any;
    preload?: boolean;
}

const Services: ServicesType = {};

ServicesMap.map(s => {
    Services[s.name] = (new ServiceFactory(api, s.uri, s.mappingThunk, s.preload)).getService();
});

export type ServicesType  = { [key in ServiceEnum]?: Service };

export default Services;