//export const BASE_URL = process.env.BACKEND_URL ?? "http://localhost:3000/api/v1/";

export const BASE_URL = "https://calc.notecash.ru/api/v1/";

export const CPU_SERIVCE_BASE_URL = "cpu/";

export const MANUFACTURER_SERIVCE_BASE_URL = "manufacturer/";

export const DISPLAY_SERVICE_BASE_URL = "display/";

export const OS_SERVICE_BASE_URL = "os/";

export const RAM_SERVICE_BASE_URL = "ram/";

export const HDD_SERVICE_BASE_URL = "memory/hdd/";

export const SSD_SERVICE_BASE_URL = "memory/ssd/";

export const LAPTOP_SEARCH_BASE_URL = "laptop/"

export const GRAPHICS_CARD_BASE_URL = "graphics-card/";

export const NAME_SEARCH_BASE_URL = "name/";
