import { LAPTOP_SEARCH_BASE_URL } from "../constants";
import { LaptopSearchDto } from "./dto/laptop-search.dto";
import { Service } from "./service";

export class LaptopServiceClass extends Service {
    public baseUrl: string;

    constructor() {
        super();
        this.baseUrl = LAPTOP_SEARCH_BASE_URL;
    }

    async search(dto: LaptopSearchDto): Promise<LaptopSearchResponse> {
        const res:any = await this.api.makeRequest(
            this.baseUrl,
            {
                method: "POST",
                body: JSON.stringify(dto),
            }
        );

        return res;
    }
}

export interface LaptopSearchResponse {
    price: number;
    laptop: {
        id: number;
        name: string;
        manufacturer: string;
    }
}

export const LaptopService = new LaptopServiceClass;