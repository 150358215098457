import { NAME_SEARCH_BASE_URL } from "../constants";
import { Service } from "./service";
import { Option } from "../../features/NotebookForm/model/interface";


export class NameServiceClass extends Service {
    public baseUrl: string;

    constructor() {
        super();
        this.baseUrl = NAME_SEARCH_BASE_URL;
    }

    
    async searchByManufacturerAndNeedleHandler(manufacturer: string, needle: string="") {
        if (!this.inProgress) {
            this.inProgress = true;
            const res:string[] = await this.api.makeRequest(
                this.getUri(manufacturer, needle),
                {
                    method: "GET",
                }
            );
            this.inProgress = false;
            return res.map(n => ({ value: n }));
        }
    }

    private getUri(manufacturer: string, needle: string="") {
        let uri = `${this.baseUrl}${manufacturer}/`
        if (needle.length) {
            uri += `?needle=${needle}`;
        }
        return uri;
    }
}

export const NameService = new NameServiceClass;