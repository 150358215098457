import { IApi, api } from "../api/api";
import { Option } from "../../features/NotebookForm/model/interface";

export class Service implements IService {
  public api: IApi;

  public baseUrl?: string

  public preload?: boolean;

  protected inProgress: boolean=false;

  constructor(_api?: IApi) {
    this.api = _api ?? api;
  }

  mapOptions(res: string[]): Option[] {
    return res.map(item => ({ value: item }));
  }

  searchByNeedleHandler = async (needle: string): Promise<Option[]> => {
    if (!needle && !this.preload) {
      return [];
    }
    if (!this.inProgress) {
      this.inProgress = true;
    
      const res:any = await this.api.makeRequest<string[]>(`${this.baseUrl}${needle}`, { method: "GET" });
      this.inProgress = false;
      return this.mapOptions(res);
    } else {
      console.log("[DEBUG] Debouncing...");
      return [];
    }
  }


  
}

export interface IService {
  api: IApi;
  baseUrl?: string;
}