import { BASE_URL } from "../constants";

export const api = {
  makeRequest: async <T> (url: string, options: RequestInit | undefined): Promise<T> => {
  const response = await fetch(`${BASE_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    ...options,
  });
  return await response.json();
  }
}

export interface IApi {
  makeRequest<T>(url: string, options: RequestInit | undefined): Promise<T>;
}

export const getCsrfToken = (): string => {
  const input = document.querySelector('[name="csrfToken"]') as HTMLInputElement;
  if (input && input.value) {
    return input.value;
  }
  return '';
}