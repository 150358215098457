import { Accordion } from "../shared/components/Accordion/Accordion";
import { AccordionSection } from "../shared/components/Accordion/AccordionSection";

import { NotebookForm } from "../features/NotebookForm/ui/NotebookForm";
import { NotebookView } from "../features/NotebookView/NotebookView";
import { PriceView } from "../features/PriceView/PriceView";
import { ContactForm } from "../features/СontactForm/ContactForm";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export const SellNotebookPage = () => {
  const laptop = useSelector((state: RootState) => state.notebookForm.laptop);
  
  return (
    <div className="sell sell--form">
      <div className="container">
        <div className="sell__wrap">
          <div className="sell__block">
            <div className="sell__text-block">
              <h2 className="sell__title">
                <span className="gradient">Расскажите о компьютере</span>
              </h2>
              <Accordion>
                <AccordionSection prefix="01" title="Заполните данные об устройстве, которое Вы продаёте" active={laptop.laptop.id === 0}>
                  <NotebookForm />
                </AccordionSection>
                <AccordionSection prefix="02" title="Вашему устройству дадут стоимость"  active={laptop.laptop.id !== 0}>
                  <PriceView />
                </AccordionSection>
                <AccordionSection prefix="03" title="Вы оставляете заявку с данными"  active={laptop.laptop.id !== 0}>
                  <h3>Контактные данные</h3>
                  <ContactForm />
                  <p>
                    Нажимая кнопку "Отправить", Вы даёте согласие на обработку персональных данных на условиях и в
                    целях, определенных{" "}
                    <a className="footer__copyright-link" href="policy/">
                      Политикой конфиденциальности
                    </a>
                    , также Вы даёте согласие на получение сообщений информационного характера в целях, связанных с
                    продажей Вашего товара.
                  </p>
                </AccordionSection>
              </Accordion>
            </div>
          </div>
          <div className="sell__block-info">
            <NotebookView />
          </div>
        </div>
      </div>
    </div>
  );
};
