import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { searchLaptopAction } from "../NotebookForm/actions/search-laptop.action";
import { LaptopSearchDto } from "../../shared/services/dto/laptop-search.dto";
import { useEffect } from "react";

export const PriceView = () => {
  const dispatch = useDispatch<AppDispatch>();

  const fields = useSelector((state: RootState) => state.notebookForm.fields);
  const laptop = useSelector((state: RootState) => state.notebookForm.laptop);
  

  const { price } = laptop;

  const { brand, model, year, ram, memory_1_type, memory_1_volume, memory_2_type, memory_2_volume, processor, diagonal, os } = fields;

  const handleCalc = () => {
    let dto:LaptopSearchDto = {}
    
    if (processor) {
      dto.cpu = processor.value;
    }

    if (model) {
      dto.name = model!.value;
    }

    if (diagonal) {
      dto.display = diagonal.value;
    }

    if (brand && brand.value) {
      dto.manufacturer = brand!.value;
    }
    // if (memory_1_type!.value=="ssd" && memory_1_volume && memory_1_volume.id) {
    //   dto.memory_ssd = memory_1_volume.value;
    // }
    // if (memory_2_type && memory_2_type!.value=="hdd" && memory_2_volume && memory_2_volume.id) {
    //   dto.memory_hdd = memory_2_volume.value;
    // }
    if (os) {
      dto.os = os.value;
    }
    if (ram) {
      dto.ram = parseInt(ram.value);
    }
    dispatch(searchLaptopAction(dto));
  }

  useEffect(() => {
    console.log("[DEBUG] Model changed: ", model);

    if (model) {
      handleCalc();
    }
  }, [model]);

  return (
    <>
      <ul className="price">
        <li>{laptop.laptop.manufacturer} { laptop.laptop.name }</li>
      </ul>
      <ul className="price">
        <li>Предложение от магазина:</li>
        <li className="price__sum">{ price } ₽</li>
      </ul>
      <p>
        Цена не изменится, если характеристики и состояние совпадут с теми, что вы указали.
        <br />
        Это проверит специалист магазина.
      </p>
      <p>На следующем шаге попросим контакты для оформления заявки.</p>
    </>
  );
};
