import { IApi, api } from "../api/api";
import { FilterParamsDto } from "./dto/filter-params.dto";
import { IService } from "./service";

export class ParamsServiceClass implements IService {
    public api: IApi;

    public baseUrl: string = "props/filter/";

    constructor(_api?: IApi) {
        this.api = _api ?? api;
    }

    private inProgress: boolean=false;

    fetchParams = async (dto: FilterParamsDto) => {
        if (!this.inProgress) {
            this.inProgress = true;
            const { brand } = dto;

            const query = this.buildQuery(dto);

            const res:any = await this.api.makeRequest(`${this.baseUrl}${brand}${query}`, { method: "GET" });
            this.inProgress = false;
            return res;
        }
    }

    private buildQuery(dto: FilterParamsDto): string {
        const params: string[] = [];

        for (let key in dto) {
            if (key != "brand") {
                params.push(`${key}=${dto[key as keyof FilterParamsDto]}`);
            }
        }

        return params.length ? `?${params.join('&')}` : '';
    }
}

export const ParamsService = new ParamsServiceClass;