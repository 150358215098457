import { ChangeEventHandler, useState } from "react";
import InputMask from "react-input-mask";

export const ContactForm = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event?.target.value);
  };
  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPhone(event?.target.value);
  };

  const handleSubmit = () => {
    // validatePhone
    console.log(name, phone);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="request__form request__form--mod"
      // action="#" method="post" encType="multipart/form-data"
    >
      <div className="request__form-group">
        <input
          className="request__input"
          id="name"
          type="text"
          placeholder="Ваше имя"
          value={name}
          onChange={handleNameChange}
        />
        <InputMask
          className="request__input"
          id="phone-2"
          name="phone"
          type="tel"
          placeholder="Введите Ваш телефон"
          mask="8 (999) 999-99-99"
          autoComplete="off"
          required
          value={phone}
          onChange={handlePhoneChange}
        />
      </div>
      <div className="request__form-btn">
        <button className="request__btn">
          <span>Отправить</span>
        </button>
      </div>
    </form>
  );
};
