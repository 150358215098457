import { Service } from "./service";
import { IApi } from "../api/api";


export class ServiceFactory {
    private service: Service;

    constructor(api: IApi, baseUrl: string, mappingThunk: any=false, preload: boolean=false) {
        this.service = new Service(api);
        this.service.baseUrl = baseUrl;
        if (mappingThunk) {
            this.service.mapOptions = mappingThunk;
        }
        this.service.preload = preload;
    }

    getService(): Service {
        return this.service;
    }
}