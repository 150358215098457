import { useEffect, useState } from "react";
import { IProps } from "../../interfaces/IProps";

export const AccordionSection = (props: IAccordionSectionProps) => {
    const { title, prefix, children, active } = props;

    const [isActive, setActive] = useState(active);

    // const handleClick = () => {
    //     setActive(!isActive);
    // }

    useEffect(() => {
        setActive(active);
    }, [active]);
    
    return (
        <>
            <div className={"accordion__head" + (isActive ? " active" : "")}>
                <div className="sell__text">
                <div className="sell__number">{prefix}</div>
                <div className="sell__text-1">
                    {title}
                </div>
                </div>
            </div>
            <div className="accordion__body" style={{display: isActive ? "block" : "none"}}>
                <div className="accordion__content">
                    {children}
                </div>
            </div>
        </>
    );
}

export interface IAccordionSectionProps extends IProps {
    title: string;
    prefix: string;
    active?: boolean;
}