import { NotebookFormParams, NotebookFormValues } from "./interface";

export const NOTEBOOK_FORM_DEFAULTS: Partial<NotebookFormValues> = {
  brand: undefined,
  model: undefined,
  year: undefined,

  memory_1_type: { value: "ssd" },
  memory_1_volume: undefined,

  memory_2_type: undefined,
  memory_2_volume: undefined,
  ram: undefined, // ram
  processor: undefined,
  diagonal: undefined,
  graphicsCard: undefined,
  os: undefined,
};

export const LAPTOP_DEFAULTS = {
  price: 0,
  laptop: {
    id: 0,
    name: "",
    manufacturer: "",
  }
}

export const NOTEBOOK_FORM_PARAMS_DEFAULT: Partial<NotebookFormParams> = {
  os: [],
  display: [],
  ram: [],
  cpu: [],
  graphics_card: [],
}
