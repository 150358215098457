import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../app/store";
import { clearForm } from "../NotebookForm/model/reducer";

export const NotebookView = () => {
  const fields = useSelector((state: RootState) => state.notebookForm.fields);
  const { 
    brand,
    model,
    year,
    memory_1_type,
    memory_1_volume,
    memory_2_type,
    memory_2_volume,
    ram,
    processor,
    diagonal,
    os
  } = fields;

  const dispatch = useDispatch<AppDispatch>();

  const handleClear = () => {
    dispatch(clearForm());
  };

  return (
    <>
      <div className="sell__img-block">
        <div className="sell__img-note active anim">
          <img src="/img/img-form-note1.png" alt="#" />
        </div>
        <div className="sell__img-note anim">
          <img src="/img/img-form-note1.png" alt="#" />
        </div>
        <img className="sell__circle abs sell__circle--form" src="/img/img-form-circle.png" alt="#" />
      </div>
      <div className="info-block">
        <div className="info-block__status">
          <div className="scale">
            <div
              className="scale__line"
              style={{
                width: `${(Object.entries(fields).filter((el) => el[1]).length / 9) * 100}%`,
              }}
            />
          </div>
        </div>
        <div className="info-block__data">
          <ul>
            {brand?.value && <li>{brand.value}</li>}
            {model?.value && <li>{model.value}</li>}
            {year?.value && <li>{year.value}</li>}
            {ram?.value && <li>ОЗУ {ram.value} Гб</li>}
            {memory_1_volume?.value && <li>{memory_1_type!.value.toUpperCase()} {memory_1_volume.value} Гб</li>}
            {memory_2_volume?.value && <li>{memory_2_type!.value.toUpperCase()} {memory_2_volume.value} Гб</li>}
            {processor?.value && <li>{processor.value}</li>}
            {diagonal?.value && <li>{diagonal.value}"</li>}
            {os?.value && <li>{os.value}</li>}
            {(brand || model || year || memory_1_volume || ram || processor || diagonal || os) && (
              <button className="clear" type="button" onClick={handleClear}>
                Очистить
              </button>
            )}
          </ul>
        </div>
        <div className="info-block__text">
          <p>Фотография просто для примера. Компьютер на ней может отличеться от того, что вы продаёте</p>
        </div>
      </div>
    </>
  );
};
