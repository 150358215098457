import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { LAPTOP_DEFAULTS, NOTEBOOK_FORM_DEFAULTS, NOTEBOOK_FORM_PARAMS_DEFAULT } from "./const";
import { NotebookFormParams, Option } from "./interface";
import { searchLaptopAction } from "../actions/search-laptop.action";
import { LaptopSearchResponse } from "../../../shared/services/laptop.service";
import { stat } from "fs";
import { filterParamsAction } from "../actions/filter-params.action";

export const notebookFormSlice = createSlice({
  name: "notebookForm",
  initialState: { 
    fields: { ...NOTEBOOK_FORM_DEFAULTS },
    laptop: { ...LAPTOP_DEFAULTS },
    params: { ...NOTEBOOK_FORM_PARAMS_DEFAULT },
    detailsSearch: false,
  },
  reducers: {
    setDetailsSearch: (state, action: PayloadAction<boolean>) => {
      state.detailsSearch = action.payload;
    },
    changeField: (state, action: PayloadAction<{ label: keyof typeof NOTEBOOK_FORM_DEFAULTS; value?: Option }>) => {
      state.fields[action.payload.label] = action.payload.value;
    },
    clearForm: (state) => {
      state.fields = { ...state.fields, ...NOTEBOOK_FORM_DEFAULTS };
      console.log("[DEBUG] State.fields: ", state.fields);
      state.laptop = { ...LAPTOP_DEFAULTS };
      state.detailsSearch = false;
    },
    clearFormOnBrandReset: (state) => {
      state.fields = { ...state.fields, ...NOTEBOOK_FORM_DEFAULTS };
    },
    clearFormOnModelReset: (state) => {
      console.log("[DEBUG] Reseted on model change: ", state.fields);
      state.fields = { ...NOTEBOOK_FORM_DEFAULTS, ...{ brand: state.fields.brand } };
      console.log("[DEBUG] Reseted on model change: ", state.fields);
    },
    setFieldsFromLaptop: (state, action: PayloadAction<LaptopModel>) => {
      console.log("[DEBUG] Setting fields from laptop...");
      const laptop = action.payload;
      for (let key in laptop) {
        switch (key) {
          case "os":
            state.fields.os = valueToOption(laptop.os.name);
            break;
          case "cpu":
            state.fields.processor = valueToOption([laptop.cpu.manufacturer, laptop.cpu.model].join(' '));
            break;
          case "ram":
            state.fields.ram = valueToOption(laptop.ram.size);
            break;
          case "display":
            state.fields.diagonal = valueToOption(laptop.display.diagonal);
            break;
          case "graphics_card":
            state.fields.graphicsCard = valueToOption(laptop.graphics_card.name);
            break;
          // case "memory_hdd":
          //   if (Number(laptop.memory_hdd.size) > 0) {
          //     state.fields.memory_1_type = valueToOption("hdd");
          //     state.fields.memory_1_volume = valueToOption(laptop.memory_hdd.size);
          //   }
          //   break;
          // case "memory_ssd":
          //   if (Number(laptop.memory_ssd.size) > 0) {
          //     state.fields.memory_1_type = valueToOption("ssd");
          //     state.fields.memory_1_volume = valueToOption(laptop.memory_ssd.size);
          //   }
          //   break;
          // case "memory_hdd_2":
          //   if (Number(laptop.memory_hdd_2.size) > 0) {
          //     state.fields.memory_2_type = valueToOption("hdd");
          //     state.fields.memory_2_volume = valueToOption(laptop.memory_hdd_2.size);
          //   }
          //   break;
          // case "memory_ssd_2":
          //   if (Number(laptop.memory_ssd_2.size) > 0) {
          //     state.fields.memory_2_type = valueToOption("ssd");
          //     state.fields.memory_2_volume = valueToOption(laptop.memory_ssd_2.size);
          //   }
          //   break;
        }
      }

      if (laptop.memory_hdd) {

      }

      if (laptop.memory_ssd) {

      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchLaptopAction.fulfilled, (state, action: PayloadAction<LaptopSearchResponse>) => {
        state.laptop = action.payload;
      })
      .addCase(searchLaptopAction.pending, (state) => {

      })
      .addCase(searchLaptopAction.rejected, (state) => {

      })
      .addCase(filterParamsAction.pending, (state) => {

      })
      .addCase(filterParamsAction.rejected, (state) => {

      })
      .addCase(filterParamsAction.fulfilled, (state, action: PayloadAction<NotebookFormParams>) => {
        state.params = { ...state.params, ...action.payload }
        console.log("[DEBUG] ===> State Params:", state.params);
      })
    }
});

export const { setDetailsSearch, changeField, clearForm, clearFormOnBrandReset, clearFormOnModelReset, setFieldsFromLaptop } = notebookFormSlice.actions;

export interface LaptopModel {
  id:            number;
  name:          string;
  manufacturer:  string;
  os:            GraphicsCard;
  cpu:           CPU;
  ram:           RAM;
  display:       Display;
  memory_ssd:    Memory;
  memory_hdd:    Memory;
  memory_ssd_2:  Memory;
  memory_hdd_2:  Memory;
  graphics_card: GraphicsCard;
}

export interface CPU {
  id:           number;
  model:        string;
  manufacturer: string;
}

export interface Display {
  id:         number;
  diagonal:   number;
  resolution: string;
}

export interface GraphicsCard {
  id:   number;
  name: string;
}

export interface Memory {
  id:   number;
  size: string;
}

export interface RAM {
  id:   number;
  size: number;
}

export const valueToOption = (value: any): Option => {
  return { value };
}

