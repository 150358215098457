// import { NotebookFormValues } from "../model/interface";
// import { NOTEBOOK_FORM_DEFAULTS } from "../model/const";

import { SelectField } from "./SelectField";
import { memory } from "../options/memory";
//import { years } from "../options/years";
import Services from "../../../shared/services";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { NameService } from "../../../shared/services/name.service";
import { useEffect, useState } from "react";
import { setDetailsSearch, setFieldsFromLaptop, clearFormOnBrandReset, clearFormOnModelReset } from "../model/reducer";
import { NotebookFormParams, Option } from "../model/interface";
import { filterParamsAction } from "../actions/filter-params.action";
import { FilterParamsDto } from "../../../shared/services/dto/filter-params.dto";
import { DetailSearch } from "./DetailSearch";

const { ManufacturerService,
} = Services;

export const NotebookForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const fields = useSelector((state: RootState) => state.notebookForm.fields);
  const laptop = useSelector((state: RootState) => state.notebookForm.laptop);
  const detailsSearch = useSelector((state: RootState) => state.notebookForm.detailsSearch);

  const { 
    brand,
    model,
  } = fields;

  const getNameSearchHandler = async(value: string) => {
    return await NameService.searchByManufacturerAndNeedleHandler(brand!.value, value);
  }

  useEffect(() => {
    //console.log("[LAPTOP CHANGED]", laptop);
    //console.log("[BRAND CHANGED]", brand);
    if (!brand && !detailsSearch) {
      //console.log("[DEBUG] dispatching form clear");
      dispatch(clearFormOnBrandReset());
    }

    if (!model && brand && !detailsSearch) {
      dispatch(clearFormOnModelReset());
    }

    if (laptop && brand && model) {
      //@ts-ignore
      dispatch(setFieldsFromLaptop(laptop.laptop));
    }

  }, [laptop, brand, model]);

  const handleDetailsClick = async () => {


    dispatch(setDetailsSearch(true));
  }

  //console.log("[DEBUG AFTER XHR] Params: ", params);

  return (
    <form>
      <h3>Укажите бренд (производителя) устройства</h3>
      <SelectField label="Бренд" name="brand" remoteSearchHandler={ManufacturerService!.searchByNeedleHandler} />

      {(brand && !detailsSearch) && <>
        <SelectField label="Модель" name="model" remoteSearchHandler={getNameSearchHandler} tooltip /> 
        <a href="#details" onClick={handleDetailsClick}>Не хочу указывать модель, перейти к поиску по характеристикам</a>
      </>}
      <span id="details"></span>
      {detailsSearch && <DetailSearch />}
    </form>
  );
};
